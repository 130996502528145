<template>
  <div>
    <!-- <table>
      <thead>
        <tr>
          <th>{{$getVisibleNames(
                  'teaching.ramacro',
                  false,
                  'Ra Macro'
                )}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="learning-result-tr">
          <td>
            <div class="text-left mt-2">
              <SentenceContainer
                :Sentence="raMacro"
                style="font-size: 18px !important"
              >
              </SentenceContainer>
            </div>
          </td>
        </tr>
      </tbody>
    </table> -->
    <div>
      <div class="flex-row">
        <div class="flex-expand"></div>
        <div>
          <b-button
            v-if="
              WeighingAvailable > 0 ||
              (matter && matter.automatic_achievement_weighing)
            "
            size="sm"
            variant="primary"
            @click="$bvModal.show('modal-evaluation-criteria')"
            >Agregar Criterio</b-button
          >
          <template v-else>
            <b-button
              size="sm"
              class="btn-disabled"
              pressed
              v-b-tooltip.hover.v-secondary.left="
                'Se ha alcanzado el 100% de ponderación.'
              "
              ><b-icon icon="exclamation-circle"></b-icon> Agregar
              Criterio</b-button
            >
          </template>
        </div>
      </div>
      <b-collapse
        id="collapse-indicators"
        class="mt-2"
        v-model="collapse_criterias"
      >
        <template v-if="draggableList.length > 0">
          <div class="pl-3">
            <b-table-simple small responsive>
              <b-thead>
                <b-tr>
                  <b-th
                    style="width: 3%"
                    class="text-center"
                    v-if="draggableList.length > 0"
                  >
                  </b-th>
                  <b-th style="width: 50%" class="text-center">
                    <strong>
                      {{
                        String(
                          this.$getVisibleNames(
                            "manual.criterio_de_evaluacion",
                            false,
                            "Criterio de Evaluación"
                          )
                        )
                      }}
                      <!-- Ponderación actions -->
                    </strong>
                  </b-th>
                  <b-th
                    v-if="matter && !matter.automatic_achievement_weighing"
                    style="width: 50%"
                    class="text-center"
                  >
                    <strong> Ponderación </strong>
                  </b-th>
                  <b-th style="width: 3%" class="text-center"> </b-th>
                </b-tr>
              </b-thead>
              <draggable v-model="draggableList" tag="b-tbody" handle=".handle">
                <template v-if="draggableList.length > 0">
                  <b-tr
                    v-for="evaluation_criteria in draggableList"
                    :key="evaluation_criteria.id"
                  >
                    <b-td
                      class="text-center handle"
                      :class="{ grabbable: true }"
                      ><b-icon icon="arrows-expand" scale="1.2"></b-icon
                    ></b-td>
                    <b-td class="text-left">
                      {{ evaluation_criteria.full_sentence }}</b-td
                    >
                    <b-td
                      v-if="matter && !matter.automatic_achievement_weighing"
                      class="text-center"
                    >
                      {{ evaluation_criteria.weighing }}%</b-td
                    >
                    <b-td class="text-center">
                      <div class="text-center button-section">
                        <button-edit
                          @click="
                            launchEvaluationCriteriaMacroForm(
                              evaluation_criteria.id
                            )
                          "
                        ></button-edit>
                        <button-delete
                          v-if="!isNaN(evaluation_criteria.id)"
                          @click="askForDeleteCriteria(evaluation_criteria.id)"
                        ></button-delete>
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </draggable>
            </b-table-simple>
            <!-- <b-table
              :fields="criterias_fields"
              :items="evaluationCriteriaMacroList"
              responsive
              small
              hover
              thead-tr-class="primary-color"
              thead-class="text-center"
            >
              <template v-slot:cell(weighing)="data">
                <div
                  v-if="matter && !matter.automatic_achievement_weighing"
                  class="text-center"
                >
                  {{ data.value + " %" }}
                </div>
                <div v-else class="text-center">N/A</div>
              </template>
              <template v-slot:cell(actions)="data">
                <div class="text-center button-section">
                  <button-edit
                    @click="launchEvaluationCriteriaMacroForm(data.item.id)"
                  ></button-edit>
                  <button-delete
                    v-if="!isNaN(data.item.id)"
                    @click="askForDeleteCriteria(data.item.id)"
                  ></button-delete>
                </div>
              </template>
            </b-table> -->
          </div>
        </template>
        <template v-else>
          <div class="text-center small">
            <em
              >No hay
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamacro",
                  true,
                  "Criterios de Evaluación"
                )
              }}
            </em>
          </div>
        </template>
      </b-collapse>
    </div>
    <!-- Modals -->
    <b-modal
      id="modal-evaluation-criteria"
      :title="
        selected_evaluation_citeria_macro
          ? String(
              'Editar ' +
                $getVisibleNames(
                  'teaching.evaluationcriteriamacro',
                  false,
                  'Criterio de Evaluación Macro'
                )
            )
          : String(
              'Crear ' +
                $getVisibleNames(
                  'teaching.evaluationcriteriamacro',
                  false,
                  'Criterio de Evaluación Macro'
                )
            )
      "
      size="lg"
      hide-footer
      @hide="resetSelectedEvaluationCriteriaMacro"
    >
      <EvaluationCriterialMacroForm
        :EvaluationCriteriaMacro="selected_evaluation_citeria_macro"
        :matter_id="matter_id"
        :max_weighing="
          selected_evaluation_citeria_macro
            ? WeighingAvailable + selected_evaluation_citeria_macro.weighing
            : WeighingAvailable
        "
        :order="evaluationCriteriaMacroList.length + 1"
        @created="slotCreatedEvaluationCriteriaMacroForm"
        @updated="slotUpdatedEvaluationCriteriaMacroForm"
      ></EvaluationCriterialMacroForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import draggable from "vuedraggable";

export default {
  name: "EvaluationCriteriaMacro",
  components: {
    draggable,
    EvaluationCriterialMacroForm: () =>
      import("@/components/reusable/EvaluationCriterialMacroForm"),
    // SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      collapse_indicators: false,
      collapse_criterias: true,
      selected_evaluation_citeria_macro: null,
    };
  },
  computed: {
    ...mapGetters({
      raMacros: names.RA_MACROS,
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
    }),
    criterias_fields() {
      return [
        {
          key: "full_sentence",
          label: String(
            this.$getVisibleNames(
              "teaching.evaluationcriteriamacro",
              false,
              "Criterio de Evaluación Macro"
            )
          ),
          thClass: "font-weight-bold",
        },
        { key: "weighing", label: "Ponderación", thClass: "font-weight-bold" },
        { key: "actions", label: "" },
      ];
    },
    draggableList: {
      get() {
        let list = this.evaluationCriteriaMacroList;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updatedEvaluationCriteria(element);
            }
          }
          order += 1;
        });
      },
    },
    matter() {
      if (!this.matter_id) return null;
      return this.matters.find((x) => x.id == this.matter_id);
    },
    raMacro() {
      if (!this.matter_id) return null;
      return this.raMacros.find((x) => x.matter == this.matter_id);
    },
    matterStudyUnits() {
      if (!this.matter_id) return [];
      return this.studyUnits.filter((x) => x.matter == this.matter_id);
    },
    evaluationCriteriaMacroList() {
      if (!this.matter_id) return [];
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    // remainingEvaluationCriteriaWeighing() {
    //   let weighing = 0;
    //   this.evaluationCriteriaMacroList.forEach((element) => {
    //     weighing += element.weighing;
    //   });
    //   return 100 - weighing;
    // },
    WeighingAvailable() {
      let weighing = 100;
      this.evaluationCriteriaMacroList.forEach((element) => {
        weighing -= element.weighing;
      });
      this.matterStudyUnits.forEach((element) => {
        weighing -= element.weighing;
      });
      return weighing;
    },
  },
  methods: {
    resetSelectedEvaluationCriteriaMacro() {
      this.selected_evaluation_citeria_macro = null;
    },
    launchEvaluationCriteriaMacroForm(evaluation_criteria_macro_id) {
      this.selected_evaluation_citeria_macro =
        this.evaluationCriteriasMacros.find(
          (x) => x.id == evaluation_criteria_macro_id
        );
      this.$bvModal.show("modal-evaluation-criteria");
    },
    slotUpdatedEvaluationCriteriaMacroForm() {
      this.$bvModal.hide("modal-evaluation-criteria");
      this.selected_evaluation_citeria_macro = null;
    },
    slotCreatedEvaluationCriteriaMacroForm() {
      this.$bvModal.hide("modal-evaluation-criteria");
    },
    askForDeleteCriteria(id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.evaluationcriteriamacro",
          false,
          "Criterio de Evaluación Macro"
        )}"?`,
        text: "Esta acción no se podrá revertir.",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.deleteCriteria(id);
      });
    },
    deleteCriteria(id) {
      this.$store
        .dispatch(names.DELETE_EVALUATION_CRITERIA_MACRO, id)
        .then(() => {
          toast(
            this.$getVisibleNames(
              "teaching.evaluationcriteriamacro",
              false,
              "Criterio de Evaluación Macro"
            ) + " Eliminado."
          );
          if (this.matter_id) {
            this.$restful
              .Get(
                `teaching/toggle_matter_automatic_achievement_weighing/?matter=${this.matter_id}`
              )
              .then(() => {
                this.$store.dispatch(
                  names.FETCH_EVALUATION_CRITERIAS_MACROS,
                  this.matter_id
                );
                this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter_id);
                this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
                  matter__id: this.matter_id,
                  force: true,
                });
              });
          }
        });
    },
    updatedEvaluationCriteria(evaluation_criteria) {
      this.$store.dispatch(names.PATCH_EVALUATION_CRITERIA_MACRO, {
        id: evaluation_criteria.id,
        item: { order: evaluation_criteria.order },
      });
    },
  },
  created() {
    if (this.matter_id)
      this.$store.dispatch(names.FETCH_MATTER, this.matter_id);
  },
};
</script>

<style scoped>
p {
  font-size: 26px !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-expand {
  flex-grow: 4;
}
td,
table {
  width: -webkit-fill-available;
  margin-left: 2%;
}
th {
  /* border: 1px solid var(--secondary-color); */
  padding: 0px 5px;
  text-align: center !important;
}
th {
  background-color: var(--primary-color);
}
.button-section {
  display: flex;
}
.competence {
  text-align: center !important;
}
.learning-result-tr {
  background: rgb(245, 245, 245);
}
.competence-badge {
  font-size: 0.8rem;
  margin-top: 6%;
  padding: 8%;
  min-width: 125px;
  letter-spacing: 0.3px;
  font-size: 14px;
  background-color: var(--primary-color);
  color: var(--secondary-font-color);
  cursor: default;
}
.institution-competence-indicator {
  display: flex;
}
.btn-disabled {
  background-color: var(--secondary-color);
  opacity: 0.7;
  border: 1px solid var(--secondary-color);
}
.btn-disabled:hover {
  background-color: var(--secondary-color);
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>